
    import {Component, Vue, Inject} from 'vue-property-decorator';
    import Footer from '../components/Footer.vue'
    import TenantSwitch from '../components/tenant-switch.vue'
    import LanguageSwitch from '../components/language-switch.vue'
    import iView from 'view-design';
    import AbpBase from '../lib/abpbase'

    @Component({
        components: {Footer, TenantSwitch, LanguageSwitch}
    })
    export default class Login extends AbpBase {
        loginModel = {
            userNameOrEmailAddress: '',
            password: '',
            rememberMe: false
        }
        showChangeTenant: boolean = false

        async login() {
            (this.$refs.loginform as any).validate(async (valid: boolean) => {
                if (valid) {
                    this.$Message.loading({
                        content: this.L('LoginPrompt'),
                        duration: 0
                    })
                    await this.$store.dispatch({
                        type: 'app/login',
                        data: this.loginModel
                    })
                    sessionStorage.setItem('rememberMe', this.loginModel.rememberMe ? '1' : '0');
                    location.reload();
                }
            });
        }

        get tenant() {
            return this.$store.state.session.tenant;
        }

        rules = {
            userNameOrEmailAddress: [
                {required: true, message: this.L('UserNameRequired'), trigger: 'blur'}
            ],
            password: [
                {required: true, message: this.L('PasswordRequired'), trigger: 'blur'}
            ]
        }

        created() {
        }
    }
