
import { Component, Vue,Inject, Prop,Watch } from 'vue-property-decorator';
import Util from '../lib/util'
import AbpBase from '../lib/abpbase'
@Component
export default class LanguageSwitch extends AbpBase{
    get languages(){
        return abp.localization.languages.filter(val=>{
            return !val.isDisabled;
        });
    }
    changeLanguage(languageName:string){
        abp.utils.setCookieValue(
            "Abp.Localization.CultureName",
            languageName,
            new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
            abp.appPath
        );
        location.reload();
    }
    get currentLanguage(){
        return abp.localization.currentLanguage;
    }
}
